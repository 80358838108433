<template>
  <main class="persons-page page">
    <BreadcrumbsComponent :title="title" />
    <div class="container-padding">
      <div v-if="persons && persons.length" class="persons-page__inner">
        <LoadingIndicator v-if="loading" title="Загрузка" />
        <div v-if="!loading" class="persons-page__content">
          <PersonComponent v-for="(person, i) in persons" :key="i" :person="person" />
        </div>
        <PaginationComponent
          :first="first"
          :page="page"
          :total="Math.ceil(total / first)"
          :totalItems="parseInt(total || '0')"
          @paginate="paginate"
        />
      </div>
      <h2 v-else class="no-info">В данный момент информация отсутствует</h2>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import PersonComponent from "./components/PersonComponent.vue";
import PERSONS_PAGE from "gql/pages/PersonsPage.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "PersonsPage",
  metaInfo() {
    return {
      title: this.title ? this.title : "Артисты",
    };
  },
  async asyncData({ route, apollo, store }) {
    let variables = {
      page: 1,
      first: 8,
      category_id: route.query.category ? parseInt(route.query.category) : null,
      art: route.name === "art" ? true : null,
      artist: route.name === "persons" ? true : null,
      administration: route.name === "administration" ? true : null,
    };
    await apollo.defaultClient
      .query({
        query: PERSONS_PAGE,
        variables: variables,
      })
      .then(({ data }) => {
        store.dispatch("persons/save", data);
      });
  },
  data() {
    return {
      page: 1,
      first: 8,
      loading: false,
    };
  },
  computed: {
    persons() {
      return this.$store.state.persons.persons.data;
    },
    total() {
      return this.$store.state.persons.persons.paginatorInfo.total;
    },
    category() {
      return this.$store.state.persons.categories_item;
    },
    title() {
      switch (this.$route.name) {
        case "administration": {
          return "Руководство театра";
        }
        case "art": {
          return "Художественный персонал";
        }
        case "persons": {
          return this.category?.title;
        }
      }
      return "Состав театра";
    },
  },
  methods: {
    paginate(e) {
      this.page = e;
      this.fetch();
    },
    fetch() {
      if (!this.loading) {
        this.loading = true;
        let variables = {
          page: this.page,
          first: this.first,
          category_id: this.$route.query.category ? parseInt(this.$route.query.category) : null,
          art: this.$route.name === "art" ? true : null,
          artist: this.$route.name === "persons" ? true : null,
          administration: this.$route.name === "administration" ? true : null,
        };
        this.$apollo
          .query({
            query: PERSONS_PAGE,
            variables: variables,
          })
          .then(({ data }) => {
            this.$store.dispatch("persons/save", data);
            this.loading = false;
          });
      }
    },
  },
  components: {
    LoadingIndicator,
    PersonComponent,
    PaginationComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.persons-page {

  &__inner {
    padding 50px 0 0 0
    display grid
    grid-gap 50px
  }

  &__content {
    display grid
    justify-items center
    grid-template-columns repeat(4, 1fr)
    grid-gap 35px
    +below(1280px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(1000px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(700px) {
      grid-template-columns 1fr
    }
  }
}
</style>
