<template>
  <router-link v-if="gallery" :to="{ name: 'culture', params: { id: gallery.id } }" class="galleries-card">
    <div class="galleries-card__shadow" />
    <ImgComponent :img="gallery.img" width="700" height="400" class="galleries-card__image" />
    <div class="galleries-card__content">
      <span v-if="gallery.title" class="galleries-card__title">{{ gallery.title }}</span>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "CultureCardComponent",
  props: {
    gallery: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.galleries-card {
  max-height 350px
  border-radius 10px
  overflow hidden
  display flex
  align-items end
  position relative

  &__shadow {
    width 100%
    height 100%
    absolute left top
    background linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 100%)
  }

  &__image {
    width 100%
    height 100%
  }

  &__content {
    position absolute
    padding 10px 15px
    z-index 1
    width 100%

    span {
      color #fff
    }
  }

  &__title {
    font-weight 600
    font-size 18px
    line-height 22px
  }
}
</style>
