<template>
  <article v-if="slide" class="banner-slide" :class="{ 'banner-slide--clickable': slide.link }">
    <a v-if="slide.link" :href="slide.link" target="_blank" rel="noopener noreferrer" class="banner-slide__link">
      <ImgComponent :img="slide.img" class="banner-slide__image" :contain="true" />
      <div class="banner-slide__blackout" />
      <div class="banner-slide__inner">
        <div class="container-padding">
          <div class="banner-slide__content">
            <div class="banner-slide__text">
              <h1 v-if="slide.title" class="banner-slide__title">{{ slide.title }}</h1>
              <span v-if="slide.subtitle" class="banner-slide__subtitle">{{ slide.subtitle }}</span>
            </div>
          </div>
        </div>
      </div>
    </a>
    <template v-else>
      <ImgComponent :img="slide.img" class="banner-slide__image" :contain="true" />
      <div class="banner-slide__blackout" />
      <div class="banner-slide__inner">
        <div class="container-padding">
          <div class="banner-slide__content">
            <div class="banner-slide__text">
              <h1 v-if="slide.title" class="banner-slide__title">{{ slide.title }}</h1>
              <span v-if="slide.subtitle" class="banner-slide__subtitle">{{ slide.subtitle }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </article>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "BannerSlide",
  props: {
    slide: Object,
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.banner-slide {
  width 100%
  height 100%
  position relative
  +below(750px) {
    height 200px
  }

  &--clickable {
    cursor pointer
  }

  &__link {
    display block
    width 100%
    height 100%
    text-decoration none
    color inherit
  }

  &__image {
    absolute left top
    width 100%
    height 100%
    background-color #000
    object-fit contain
  }

  &__blackout {
    absolute left top
    width 100%
    height 100%
    background linear-gradient(234.97deg, rgba(0, 0, 0, 0.08) 29.39%, rgba(0, 0, 0, 0.71) 86.57%)
    mix-blend-mode normal
    opacity 0.8
  }

  &__inner {
    display flex
    height 100%
  }

  &__content {
    display flex
    align-items flex-end
    justify-content space-between
    padding 0 0 50px 0
    height 100%
    +below(750px) {
      align-items center
      justify-content center
      padding 0
    }
  }

  &__text {
    display flex
    flex-direction column
    +below(750px) {
      text-align center
    }
  }

  &__title {
    color var(--white)
    max-width 765px
    +below(1000px) {
      font-size 4rem
      line-height 4.375rem
    }
    +below(750px) {
      font-size 3.5rem
      line-height 4.125rem
    }
    +below(650px) {
      font-size 2.625rem
      line-height 3.25rem
    }
    +below(480px) {
      font-size 2rem
      line-height 2.75rem
      margin-bottom 15px
    }
    +below(375px) {
      font-size 1.625rem
    }
  }

  &__subtitle {
    font-weight 500
    font-size .875rem
    line-height 17px
    color var(--white_o5)
    +below(480px) {
      font-size .75rem
      line-height 14px
    }
  }
}
</style>
