export default {
  namespaced: true,
  state: () => ({
    items: [],
    total: 0,
    posters_item: null
  }),
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items;
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
    SET_POSTER_ITEM(state, item) {
      state.posters_item = item;
    }
  },
  actions: {
    save({ commit }, data) {
      if (data && data.posters) {
        commit('SET_ITEMS', data.posters.data);
        commit('SET_TOTAL', data.posters.paginatorInfo.total);
      }
      if (data && data.posters_item) {
        commit('SET_POSTER_ITEM', data.posters_item);
      }
    }
  }
};
