<template>
  <main class="gallery-page page">
    <BreadcrumbsComponent
      :title="gallery.title"
      :links="[{ title: 'События', to: { name: 'galleries' } }]"
    />
    <div class="container-padding">
      <div v-if="gallery" class="gallery-page__inner">
        <div class="gallery-page__body">
          <h2 v-if="gallery.title" class="gallery-page__title">{{ gallery.title }}</h2>
          <span v-if="gallery.description">{{ gallery.description }}</span>
        </div>
        <ul v-if="gallery.images" class="gallery-page__images">
          <li v-for="(image, i) in gallery.images" :key="i">
            <a :href="image.img.path" @click.prevent="showGallery(gallery.images, i)">
              <ImgComponent :img="image.img" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import GalleryModal from "components/modals/components/GalleryModal.vue";
import GALLERIES_OPEN_PAGE from "gql/pages/GalleriesOpenPage.graphql";
export default {
  name: "GalleriesOpenPage",
  metaInfo() {
    const meta = this.$store.state.galleries.galleries_item;
    return {
      title: meta?.title,
    };
  },
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: GALLERIES_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("galleries/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    gallery() {
      return this.$store.state.galleries.galleries_item;
    },
  },
  methods: {
    showGallery(images, index = 0) {
      this.$store.state._modals = [
        {
          component: GalleryModal,
          options: {
            images,
            index,
          },
        },
      ];
    },
  },
  components: {
    ImgComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.gallery-page {

  &__inner {
    padding 50px 0 0 0
    display grid
    grid-gap 30px
  }

  &__body {
    display grid
    grid-gap 15px
  }

  &__title {
    margin-bottom 0
  }

  &__images {
    display flex
    flex-wrap wrap
    gap 15px

    li {
      display inline-flex
      width 100%
      max-width 330px
      +below(700px) {
        max-width 400px
      }

      a {
        display inline-flex
        width 100%

        img {
          max-height 320px
          border-radius 10px
        }
      }
    }
  }
}
</style>
