<template>
  <nav class="m-menu">
    <div @click="closeMobileMenu" class="m-menu__background" />
    <div class="m-menu__container">
      <button @click="closeMobileMenu" class="m-menu__close">
        <IconComponent category="default" name="close" />
      </button>
      <ul class="m-menu__links">
        <li v-for="(link, i) in links" :key="i">
          <div>
            <router-link :to="link.route">
              <span>{{ link.title }}</span>
            </router-link>
            <button @click="toggleLink(i)">
              <IconComponent v-if="link.subLinks && link.subLinks.length" name="arrow-down-fill" />
            </button>
          </div>
          <ul v-if="link.subLinks && link.subLinks.length && activeLink === i">
            <li v-for="(subLink, i) in link.subLinks" :key="i">
              <router-link :to="subLink.route">
                {{ subLink.title }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
      <div class="m-menu__buttons">
        <a @click="closeMobileMenu" class="btn btn--md btn--main bvi-open">Версия для слабовидящих</a>
        <a
          @click="closeMobileMenu"
          href="#afisha/014311f843f004f2d9d82b76b93b245ddced0cda24f729"
          class="btn btn--md btn--main"
        >
          Купить билет
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "MobileMenu",
  data() {
    return {
      activeLink: null,
      links: [
        {
          title: "Афиша",
          route: { name: "posters" },
        },
        {
          title: "Артисты",
          route: { name: "" },
          subLinks: [],
        },
        {
          title: "Репертуар",
          route: { name: "" },
          subLinks: [],
        },
        {
          title: "О театре",
          route: { name: "" },
          subLinks: [
            {
              title: "Руководство театра",
              route: { name: "administration" },
            },
            {
              title: "Художественный персонал",
              route: { name: "art" },
            },
            {
              title: "История театра",
              route: { name: "history" },
            },
            {
              title: "Миссия театра",
              route: { name: "mission" },
            },
            {
              title: "События",
              route: { name: "galleries" },
            },
            // {
            //   title: "Культура против террора",
            //   route: { name: "cultures" },
            // },
            {
              title: "СМИ о нас",
              route: { name: "media" },
            },
          ],
        },
        {
          title: "Культура против террора",
          route: { name: "cultures" },
        },
        {
          title: "СМИ о нас",
          route: { name: "media" },
        },
        {
          title: "Документы",
          route: { name: "documents" },
          subLinks: [
            {
              title: "Противодействие терроризму и экстремизму",
              route: { name: "countering_extremism" },
            },
            {
              title: "Противодействие коррупции",
              route: { name: "anti_corruption" },
            },
            {
              title: "Документы театра",
              route: { name: "theater_documents" },
            },
          ],
        },
        {
          title: "Контакты",
          route: { name: "contacts" },
        },
      ],
    };
  },
  created() {
    this.links[1].subLinks = this.categories_persons;
    this.links[2].subLinks = this.categories_repertoire;
  },
  computed: {
    categories_repertoire() {
      return this.$store.state.categories_repertoire
        ? this.$store.state.categories_repertoire.map((item) => ({
            title: item.title,
            route: {
              name: "repertoires",
              query: { category: item.id },
            },
          }))
        : [];
    },
    categories_persons() {
      return this.$store.state.categories_persons
        ? this.$store.state.categories_persons.map((item) => ({
            title: item.title,
            route: {
              name: "persons",
              query: { category: item.id },
            },
          }))
        : [];
    },
  },
  mounted() {
    require(["bvi"], ({ Bvi }) => {
      new Bvi();
    });
  },
  watch: {
    "$route.name"() {
      document.body.classList.remove("no-scroll");
      this.$store.state.showMobileMenu = false;
    },
    "$route.query.category"() {
      document.body.classList.remove("no-scroll");
      this.$store.state.showMobileMenu = false;
    },
  },
  methods: {
    closeMobileMenu() {
      document.body.classList.remove("no-scroll");
      this.$store.state.showMobileMenu = false;
    },
    toggleLink(i) {
      if (this.activeLink === i) {
        this.activeLink = null;
        return;
      }
      this.activeLink = i;
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/m-menu.styl"
</style>
