import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/contacts",
      name: "contacts",
      component: () => import("./views/contacts/index.vue"),
    },
    {
      path: "/mission",
      name: "mission",
      component: () => import("./views/mission/index.vue"),
    },
    {
      path: "/history",
      name: "history",
      component: () => import("./views/history/index.vue"),
    },
    {
      path: "/cultures",
      name: "cultures",
      component: () => import("./views/cultures/index.vue"),
    },
    {
      path: "/cultures/:id",
      name: "culture",
      component: () => import("./views/cultures/open.vue"),
    },
    {
      path: "/galleries",
      name: "galleries",
      component: () => import("./views/galleries/index.vue"),
    },
    {
      path: "/galleries/:id",
      name: "gallery",
      component: () => import("./views/galleries/open.vue"),
    },
    {
      path: "/media",
      name: "media",
      component: () => import("./views/media/index.vue"),
    },
    {
      path: "/media/:id",
      name: "media-open",
      component: () => import("./views/media/open.vue"),
    },
    {
      path: "/posters",
      name: "posters",
      component: () => import("./views/posters/index.vue"),
    },
    {
      path: "/posters/:id",
      name: "poster",
      component: () => import("./views/posters/open.vue"),
    },
    {
      path: "/repertoires",
      name: "repertoires",
      component: () => import("./views/repertoires/index.vue"),
    },
    {
      path: "/repertoires/:id",
      name: "repertoire",
      component: () => import("./views/repertoires/open.vue"),
    },
    {
      path: "/persons",
      name: "persons",
      component: () => import("./views/persons/index.vue"),
    },
    {
      path: "/person/:id",
      name: "person",
      component: () => import("./views/persons/open.vue"),
    },
    {
      path: "/persons/administration",
      name: "administration",
      component: () => import("./views/persons/index.vue"),
    },
    {
      path: "/persons/art",
      name: "art",
      component: () => import("./views/persons/index.vue"),
    },
    {
      path: "/documents",
      name: "documents",
      component: () => import("./views/documents/index.vue"),
      meta: {
        title: "Документы",
        countering_extremism: false,
        anti_corruption: false,
        theater_documents: false,
      },
    },
    {
      path: "/documents/:id",
      name: "document",
      component: () => import("./views/documents/open.vue"),
      meta: {
        countering_extremism: false,
        anti_corruption: false,
        theater_documents: false,
      },
    },
    {
      path: "/countering-extremism",
      name: "countering_extremism",
      component: () => import("./views/documents/index.vue"),
      meta: {
        title: "Противодействие терроризму и экстремизму",
        countering_extremism: true,
        anti_corruption: false,
        theater_documents: false,
      },
    },
    {
      path: "/anti-corruption",
      name: "anti_corruption",
      component: () => import("./views/documents/index.vue"),
      meta: {
        title: "Противодействие коррупции",
        countering_extremism: false,
        anti_corruption: true,
        theater_documents: false,
      },
    },
    {
      path: "/theater-documents",
      name: "theater_documents",
      component: () => import("./views/documents/index.vue"),
      meta: {
        title: "Документы театра",
        countering_extremism: false,
        anti_corruption: false,
        theater_documents: true,
      },
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
