<template>
  <section class="home-posters">
    <div class="container-padding">
      <div class="home-posters__inner">
        <div class="home-posters__header">
          <h2 class="home-posters__title">Афиша</h2>
          <a href="#afisha/014311f843f004f2d9d82b76b93b245ddced0cda24f729" class="btn btn--md btn--main buy-ticket-button">Купить билет</a>
        </div>
        <div v-if="posters && posters.length" class="home-posters__content">
          <LoadingIndicator v-if="loading" title="Загрузка" />
          <div v-if="!loading" class="home-posters__grid">
            <PostersCardComponent v-for="(poster, i) in posters" :key="i" :poster="poster" />
          </div>
          <PaginationComponent
            :first="first"
            :page="page"
            :total="Math.ceil(total / first)"
            :totalItems="parseInt(total || '0')"
            @paginate="paginate"
          />
        </div>
        <h2 v-else class="no-info">В данный момент информация отсутствует</h2>
      </div>
    </div>
  </section>
</template>

<script>
import PostersCardComponent from "../../posters/components/PostersCardComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import POSTERS_PAGE from "gql/pages/PostersPage.graphql";

export default {
  name: "HomePostersPaginationComponent",
  data() {
    return {
      page: 1,
      first: 6,
      loading: false,
      filterType: 'all' // Changed from 'active' to 'all' to show all posters
    };
  },
  computed: {
    posters() {
      return this.$store.state.posters.items;
    },
    total() {
      return this.$store.state.posters.total;
    }
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async paginate(e) {
      this.page = e;
      await this.fetch();
    },
    async fetch() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: POSTERS_PAGE,
          variables: {
            page: this.page,
            first: this.first,
            filterDates: this.filterType
          },
        });
        this.$store.dispatch("posters/save", data);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
  },
  components: {
    LoadingIndicator,
    PaginationComponent,
    PostersCardComponent,
  },
};
</script>

<style lang="stylus">
.home-posters {
  &__header {
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px
  }

  &__grid {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px 20px
    margin-bottom 30px
    
    +below(1000px) {
      grid-template-columns repeat(2, 1fr)
    }
    
    +below(700px) {
      grid-template-columns 1fr
    }
  }

  &__title {
    margin-bottom 30px
  }

  .buy-ticket-button {
    background-color: #FF5F1F !important
    border-color: #FF5F1F !important
    
    &:hover {
      background-color: darken(#FF5F1F, 10%) !important
      border-color: darken(#FF5F1F, 10%) !important
      color: var(--white) !important
    }
  }
}
</style>
