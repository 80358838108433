<template>
  <main class="cultures-page page">
    <BreadcrumbsComponent title="Культура против террора" />
    <div class="container-padding">
      <div v-if="galleries && galleries.length" class="cultures-page__inner">
        <LoadingIndicator v-if="loading" title="Загрузка" />
        <div v-if="!loading" class="cultures-page__content">
          <CultureCardComponent v-for="(gallery, i) in galleries" :key="i" :gallery="gallery" />
        </div>
        <PaginationComponent
          :first="first"
          :page="page"
          :total="Math.ceil(total / first)"
          :totalItems="parseInt(total || '0')"
          @paginate="paginate"
        />
      </div>
      <h2 v-else class="no-info">В данный момент информация отсутствует</h2>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import CultureCardComponent from "./components/CultureCardComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import GALLERIES_PAGE from "gql/pages/GalleriesPage.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "CulturePage",
  metaInfo: {
    title: "Культура против террора",
  },
  async asyncData({ apollo, store }) {
    let variables = {
      page: 1,
      first: 6,
      is_culture_vs_terror: true
    };
    await apollo.defaultClient
      .query({
        query: GALLERIES_PAGE,
        variables: variables,
      })
      .then(({ data }) => {
        store.dispatch("galleries/save", data);
      });
  },
  data() {
    return {
      page: 1,
      first: 6,
      loading: false,
    };
  },
  computed: {
    galleries() {
      return this.$store.state.galleries.galleries.data;
    },
    total() {
      return this.$store.state.galleries.galleries.paginatorInfo.total;
    },
  },
  methods: {
    paginate(e) {
      this.page = e;
      this.fetch();
    },
    fetch() {
      if (!this.loading) {
        this.loading = true;
        let variables = {
          page: this.page,
          first: this.first,
          is_culture_vs_terror: true
        };
        this.$apollo
          .query({
            query: GALLERIES_PAGE,
            variables: variables,
          })
          .then(({ data }) => {
            this.$store.dispatch("galleries/save", data);
            this.loading = false;
          });
      }
    },
  },
  components: {
    LoadingIndicator,
    BreadcrumbsComponent,
    CultureCardComponent,
    PaginationComponent,
  },
};
</script>

<style lang="stylus">
.cultures-page {
  &__inner {
    padding 50px 0 0 0
  }

  &__content {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px
    margin-bottom 30px
  }
}

@media (max-width: 1200px) {
  .cultures-page {
    &__content {
      grid-template-columns repeat(2, 1fr)
    }
  }
}

@media (max-width: 768px) {
  .cultures-page {
    &__content {
      grid-template-columns 1fr
    }
  }
}
</style>
