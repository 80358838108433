<template>
  <main class="culture-page page">
    <BreadcrumbsComponent
      :title="gallery.title"
      :links="[{ title: 'Культура против террора', to: { name: 'cultures' } }]"
    />
    <div class="container-padding">
      <div v-if="gallery" class="culture-page__inner">
        <div class="culture-page__body">
          <h2 v-if="gallery.title" class="culture-page__title">{{ gallery.title }}</h2>
          <span v-if="gallery.description">{{ gallery.description }}</span>
        </div>
        <ul v-if="gallery.images" class="culture-page__images">
          <li v-for="(image, i) in gallery.images" :key="i">
            <a :href="image.img.path" @click.prevent="showGallery(gallery.images, i)">
              <ImgComponent :img="image.img" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import GalleryModal from "components/modals/components/GalleryModal.vue";
import GALLERIES_CULTURE_OPEN_PAGE from "gql/pages/GalleriesCultureOpenPage.graphql";

export default {
  name: "CultureOpenPage",
  metaInfo() {
    const meta = this.$store.state.galleries.galleries_item;
    return {
      title: meta?.title,
    };
  },
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: GALLERIES_CULTURE_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        if (data && data.galleries_item && data.galleries_item.is_culture_vs_terror) {
          store.dispatch("galleries/save", { galleries_item: data.galleries_item });
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    gallery() {
      return this.$store.state.galleries.galleries_item;
    },
  },
  methods: {
    showGallery(images, index = 0) {
      this.$store.state._modals = [
        {
          component: GalleryModal,
          options: {
            images,
            index,
          },
        },
      ];
    },
  },
  components: {
    ImgComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.culture-page {
  &__inner {
    padding 50px 0 0 0
    display grid
    grid-gap 30px
  }

  &__body {
    display grid
    grid-gap 15px
  }

  &__title {
    margin-bottom 0
  }

  &__images {
    margin 0
    padding 0
    list-style none
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px

    li {
      position relative
      padding-bottom 65%
      overflow hidden

      a {
        position absolute
        top 0
        left 0
        width 100%
        height 100%
      }
    }
  }
}

@media (max-width: 1200px) {
  .culture-page {
    &__images {
      grid-template-columns repeat(2, 1fr)
    }
  }
}

@media (max-width: 768px) {
  .culture-page {
    &__images {
      grid-template-columns 1fr
    }
  }
}
</style>
