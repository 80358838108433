<template>
  <main class="documents-page page">
    <BreadcrumbsComponent :title="$route.meta.title" />
    <div class="container-padding">
      <div v-if="documents && documents.length" class="documents-page__inner">
        <span class="documents-page__total">Всего документов: {{ total }}</span>
        <ul class="documents-page__list">
          <li v-for="(document, i) in documents" :key="i">
            <DocumentItemComponent :document="document" />
          </li>
        </ul>
        <PaginationComponent
          :first="first"
          :page="page"
          :total="Math.ceil(total / first)"
          :totalItems="parseInt(total || '0')"
          @paginate="paginate"
        />
      </div>
      <h2 v-else class="no-info">В данный момент информация отсутствует</h2>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import DocumentItemComponent from "./components/DocumentItemComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import DOCUMENTS_PAGE from "gql/pages/DocumentsPage.graphql";

export default {
  name: "DocumentsPage",
  metaInfo() {
    const meta = this.$route.meta;
    return {
      title: meta?.title,
    };
  },
  async asyncData({ route, apollo, store }) {
    let variables = {
      page: 1,
      first: 6,
      countering_extremism: route.meta.countering_extremism ? true : null,
      anti_corruption: route.meta.anti_corruption ? true : null,
      theater_documents: route.meta.theater_documents ? true : null,
    };
    await apollo.defaultClient
      .query({
        query: DOCUMENTS_PAGE,
        variables: variables,
      })
      .then(({ data }) => {
        store.dispatch("documents/save", data);
      });
  },
  data() {
    return {
      page: 1,
      first: 6,
    };
  },
  computed: {
    documents() {
      return this.$store.state.documents.documents_paginate.data;
    },
    total() {
      return this.$store.state.documents.documents_paginate.paginatorInfo.total;
    },
  },
  methods: {
    paginate(e) {
      this.page = e;
      this.fetch();
    },
    fetch() {
      let variables = {
        page: this.page,
        first: this.first,
        countering_extremism: this.$route.meta.countering_extremism ? true : null,
        anti_corruption: this.$route.meta.anti_corruption ? true : null,
        theater_documents: this.$route.meta.theater_documents ? true : null,
      };
      this.$apollo
        .query({
          query: DOCUMENTS_PAGE,
          variables: variables,
        })
        .then(({ data }) => {
          this.$store.dispatch("documents/save", data);
        });
    },
  },
  components: {
    BreadcrumbsComponent,
    DocumentItemComponent,
    PaginationComponent,
  },
};
</script>

<style lang="stylus">
.documents-page {

  &__inner {
    padding 50px 0 0 0
    display grid
    grid-gap 50px
  }

  &__total {
    font-weight 500
    font-size .875rem
    line-height 22px
    color var(--dark)
  }

  &__list {
    display grid
    grid-gap 15px
  }
}
</style>
