<template>
  <main class="posters-page page">
    <BreadcrumbsComponent title="Афиша" />
    <div class="container-padding">
      <div class="posters-filter">
        <select v-model="filterType" class="posters-filter__select" @change="handleFilterChange">
          <option value="all">Все афиши</option>
          <option value="active">Активные</option>
        </select>
      </div>
      <div v-if="posters && posters.length" class="posters-page__inner">
        <LoadingIndicator v-if="loading" title="Загрузка" />
        <div v-if="!loading" class="posters-page__content">
          <PostersCardComponent v-for="(poster, i) in posters" :key="i" :poster="poster" />
        </div>
        <PaginationComponent
          :first="first"
          :page="page"
          :total="Math.ceil(total / first)"
          :totalItems="parseInt(total || '0')"
          @paginate="paginate"
        />
      </div>
      <h2 v-else class="no-info">В данный момент информация отсутствует</h2>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import PostersCardComponent from "./components/PostersCardComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import POSTERS_PAGE from "gql/pages/PostersPage.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";
import moment from 'moment';

export default {
  name: "PostersPage",
  metaInfo: {
    title: "Афиша",
  },
  async asyncData({ apollo, store }) {
    let variables = {
      page: 1,
      first: 6,
      filterDates: 'all'
    };
    await apollo.defaultClient
      .query({
        query: POSTERS_PAGE,
        variables: variables,
      })
      .then(({ data }) => {
        store.dispatch("posters/save", data);
      });
  },
  data() {
    return {
      page: 1,
      first: 6,
      loading: false,
      filterType: 'all'
    };
  },
  computed: {
    posters() {
      return this.$store.state.posters.items;
    },
    total() {
      return this.$store.state.posters.total;
    }
  },
  methods: {
    async handleFilterChange() {
      this.page = 1; // Сбрасываем страницу при смене фильтра
      await this.fetch();
    },
    async paginate(e) {
      this.page = e;
      await this.fetch();
    },
    async fetch() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: POSTERS_PAGE,
          variables: {
            page: this.page,
            first: this.first,
            filterDates: this.filterType
          },
        });
        this.$store.dispatch("posters/save", data);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
  },
  components: {
    LoadingIndicator,
    PostersCardComponent,
    PaginationComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.posters-filter
  margin 20px 0
  display flex
  justify-content flex-end
  
  &__select
    padding 10px 15px
    font-size 16px
    border 1px solid #ddd
    border-radius 4px
    background-color white
    cursor pointer
    min-width 150px
    appearance none
    background-image url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")
    background-repeat no-repeat
    background-position right 10px center
    background-size 1em
    padding-right 35px
    
    &:focus
      outline none
      border-color #007bff

.posters-page
  &__inner
    padding 50px 0 0 0
    display grid
    grid-gap 50px

  &__content
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 30px 20px
    +below(900px)
      grid-template-columns 1fr

.no-info
  text-align center
  color #666
  margin-top 40px
</style>
