<template>
  <section class="home-links">
    <router-link v-for="(link, i) in links" :key="i" :to="link.route" class="btn btn--md btn--outline">
      <span>{{ link.title }}</span>
    </router-link>
  </section>
</template>

<script>
export default {
  name: "HomeLinksComponent",
  data() {
    return {
      links: [
        {
          title: "История театра",
          route: { name: "history" },
        },
        {
          title: "Миссия театра",
          route: { name: "mission" },
        },
        {
          title: "События",
          route: { name: "galleries" },
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
.home-links {
  background var(--main)
  padding 25px 5px
  display flex
  flex-wrap wrap
  align-items center
  justify-content center
  gap 20px
}
</style>
